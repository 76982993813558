/* Colors  */
:root {
    --PrimaryColor: hsl(26, 93%, 50%);
    --HoverColor: hsl(26, 90%, 42%);
    --whiteColor: hsl(0, 0%, 100%);
    --blackColor: hsl(0, 0%, 18%);
    --textColor: hsl(240, 1%, 48%);
    --whiteColorDeam: hsl(0, 0%, 93%);
    --greyBg: hsl(0, 0%, 96%);
    --greyText: rgb(190, 190, 190);
    --inputColor: hsl(330, 12%, 97%);
}

.home {
    height: 70vh;
    // background: linear-gradient(rgba(33,33,33,0.522),rgba(33,33,33,0.522),url("../../Assets/pexels-aleksandar-pasaric-3342739.jpg"));
    // background: url("../../Assets/recruitment.jpg");
    animation: animate 16s ease-in-out infinite;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    position: relative;

    @keyframes animate {

        0%,
        100% {
            background-image: url('../../Assets/construction.jpeg');
        }

        25% {
            background-image: url('../../Assets/unskill_carpenter.jpeg');
        }

        50% {
            background-image: url('../../Assets/engineer.jpeg');
        }

        75% {
            background-image: url('../../Assets/shuttering_carpenter.jpeg');
        }
    }

    .secContainer {

        .homeText {
            text-align: center;

            .title {
                color: var(--whiteColor);
                font-size: 2rem;
                font-weight: 700;
                line-height: 2.5rem;
                text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;

            }

            .subTitle { 
                color: var(--whiteColor);
                opacity: .9;
                font-size: 13px;
                font-weight: 300;
                padding: 1rem 0;
                max-width: 70%;
                text-align: center;
                margin: auto;
                text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;

            }
        }

        .homeCard {
            width: 80%;
            border-radius: 1rem;
            padding: 2rem;
            background: var(--textColor);
            position: absolute;
            // gap: 1rem;
            // justify-content: center;
            bottom: -30%;
            // transform: translate(-50%);
            // left: 50%;
            box-shadow: 0 2px 8px 4px rgba(178, 178, 178, 0.45);
            height: 200px;
            h1{
                color: var(--PrimaryColor);
                text-align: center;
                text-shadow: -0.5px -0.5px 0 #000, 0.5px -0.5px 0 #000, -0.5px 0.5px 0 #000, 0.5px 0.5px 0 #000;

            }
            
        }

    }
}

.carousel{
    .control-dots{
       .dot{
        background: var(--greyBg) !important;
       }
    }
}

.slider-wrapper{
    .slider{
        .slide{
            display: flex;
            align-items: center;
            justify-content: center;
            div{
                
                img{
                    height: 100px !important;
                }
                width: 80% !important;
            }
        }
    }
}



// MEDIA QUERIES
@media screen and (min-width:280px){
    .home{
        .secContainer{
            .homeCard{
                width: 82%;
                bottom: -100px;
                .slider-wrapper{
                    .slider{
                        .slide{
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            div{
                                
                                img{
                                    height: 90px !important;
                                }
                                width: 50% !important;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width:320px){
    .home{
        .secContainer{
            .homeCard{
                width: 80%;
            }
        }
    }
}

@media screen and (min-width:375px){
    .home{
        .secContainer{
            .homeCard{
                width: 85%;
                bottom: -100px;
            }
        }
    }
}

@media screen and (min-width:360px){
    .home{
        .secContainer{
            .homeCard{
                width: 85%;
                bottom: -100px;
            }
        }
    }
}

@media screen and (min-width:390px){
    .home{
        .secContainer{
            .homeCard{
                width: 85%;
                bottom: -100px;
            }
        }
    }
}

@media screen and (min-width:393px){
    .home{
        .secContainer{
            .homeCard{
                width: 87%;
                bottom: -100px;
            }
        }
    }
}

@media screen and (min-width:768px){
    .home{
        .secContainer{
            .homeCard{
                width: 87% !important;
                bottom: -100px !important;
            }
        }
    }
}

@media screen and (min-width:414px){
    .home{
        .secContainer{
            .homeCard{
                width: 85%;
                bottom: -100px;
            }
        }
    }
}

@media screen and (min-width:480px){
    .home{
        .secContainer{
            .homeCard{
                bottom: -100px;
                width: 90%;
                grid-template-columns: repeat(2,1fr);
            }
        }
    }
}

@media screen and (min-width:556px){
    .home{
        .secContainer{
            .homeCard{
                width: 90%;
            }

            .homeText{
                .title{
                    font-size: 2.5rem;
                }
                .subTitle{
                    font-size: 18px;
                }
            }
        }
    }
}

@media screen and (min-width:576px){
    .home{
        .secContainer{
            .homeCard{
                width: 90%;
            }
        }
    }
}

@media screen and (min-width:720px){
    .home{
        .secContainer{
            .homeCard{
                width: 62%;
                bottom: -20%;
                grid-template-columns: repeat(4,1fr);
                .slider-wrapper{
                    .slider{
                        .slide{
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            div{
                                
                                img{
                                    height: 100px !important;
                                }
                                width: 50% !important;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width:912px){
    .home{
        .secContainer{
            .homeCard{
                width: 70% !important;
                bottom: -100px;
            }
        }
    }
}

@media screen and (min-width:576px){
    .home{
        .secContainer{
            .homeCard{
                width: 85% !important;
                bottom: -100px;
            }
        }
    }
}


@media screen and (min-width:993){
    .homeCard{
        width: 87%;
    }
}

@media screen and (min-width:720px){
    .home{
        .secContainer{
            .homeText{
                padding: 1rem 10rem;
                .title{
                    font-size: 3.5rem;
                    margin: auto;
                    line-height: 4rem;
                }
                .subTitle{
                    width: 70%;
                }
            }
        }
    }
}

@media screen and (min-width:820px){
    .home{
        .secContainer{
            .homeCard{
                width: 85%;
                bottom: -100px;
                .slider-wrapper{
                    .slider{
                        .slide{
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            div{
                                
                                img{
                                    height: 100px !important;
                                }
                                width: 50% !important;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width:1024px){
    .home{
        height: 80vh !important;
        .secContainer{
            .homeText{
                margin-top: 50px;
                .subTitle{
                    font-size: 30px;
                }
            }

            .homeCard{
                width: 87% !important;
                bottom: -120px !important;
            }
        }
    }
}

@media screen and (min-width:1280px){
    .home{
        .secContainer{
            .homeText{
                .subTitle{
                    font-size: 30px;
                }
            }

            .homeCard{
                width: 70% !important;
                bottom: -120px !important;
            }
        }
    }
}