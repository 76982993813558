/* Colors  */
:root {
  --PrimaryColor: hsl(26, 93%, 50%);
  --HoverColor: hsl(26, 90%, 42%);
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(0, 0%, 18%);
  --textColor: hsl(240, 1%, 48%);
  --whiteColorDeam: hsl(0, 0%, 93%);
  --greyBg: hsl(0, 0%, 96%);
  --greyText: rgb(190, 190, 190);
  --inputColor: hsl(330, 12%, 97%);
}

.service .title {
  color: var(--PrimaryColor);
  line-height: 1.5rem;
  padding-bottom: 0.5rem;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 800;
}
.service .videoCard {
  margin-top: 2rem;
  background: rgb(34, 193, 195);
  background: linear-gradient(0deg, rgb(34, 193, 195) 0%, rgba(253, 139, 45, 0.84357493) 89%);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.service .videoCard .cardContent {
  gap: 2rem;
  padding: 2rem 1rem;
}
.service .videoCard .cardContent .cardText h2 {
  color: var(--whiteColor);
  font-weight: 600;
  padding-bottom: 1rem;
  text-shadow: -0.5px -0.5px 0 var(--greyText), 0.5px -0.5px 0 var(--greyText), -0.5px 0.5px 0 var(--greyText), 0.5px 0.5px 0 var(--greyText);
}
.service .videoCard .cardContent .cardText p {
  color: var(--blackColor);
  opacity: 0.7;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 1px;
  text-shadow: -0.5px -0.5px 0 var(--greyText), 0.5px -0.5px 0 var(--greyText), -0.5px 0.5px 0 var(--greyText), 0.5px 0.5px 0 var(--greyText);
}
.service .videoCard .cardContent .cardVideo1 {
  border: 2px solid var(--whiteColor);
  height: 200px;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  animation: animate 10s ease-in-out infinite;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  animation-name: card1;
}
@keyframes card1 {
  0%, 100% {
    background-image: url("../../Assets/office_staff.jpeg");
  }
  25% {
    background-image: url("../../Assets/hospitality.jpeg");
  }
  50% {
    background-image: url("../../Assets/engineer.jpeg");
  }
  75% {
    background-image: url("../../Assets/hospital_stall.jpeg");
  }
}
.service .videoCard .cardContent .cardVideo2 {
  border: 2px solid var(--whiteColor);
  height: 200px;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  animation: animate 10s ease-in-out infinite;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  animation-name: card2;
}
@keyframes card2 {
  0%, 100% {
    background-image: url("../../Assets/construction.jpeg");
  }
  14% {
    background-image: url("../../Assets/unskill_carpenter.jpeg");
  }
  28% {
    background-image: url("../../Assets/driver.jpeg");
  }
  42% {
    background-image: url("../../Assets/security.jpeg");
  }
  56% {
    background-image: url("../../Assets/oil.jpeg");
  }
  70% {
    background-image: url("../../Assets/delivery.jpeg");
  }
  84% {
    background-image: url("../../Assets/shuttering_carpenter.jpeg");
  }
}

@media screen and (min-width: 320px) {
  .service {
    padding: 8rem 0;
  }
}
@media screen and (min-width: 720px) {
  .service .videoCard {
    box-shadow: 0 2px 8px 4px rgba(74, 70, 70, 0.376);
    border-radius: 1rem;
  }
  .service .videoCard .cardContent {
    gap: 4rem;
    padding: 4rem 3rem;
  }
  .service .videoCard .cardContent .cardText h2 {
    font-size: 2rem;
  }
  .service .videoCard .cardContent .cardText p {
    opacity: 0.7;
    font-size: 15px;
  }
  .service .videoCard .cardContent {
    grid-template-columns: repeat(2, 1fr) !important;
  }
}
@media screen and (min-width: 1024px) {
  .service .mainContent {
    padding: 2.5rem 0;
  }
}/*# sourceMappingURL=service.css.map */