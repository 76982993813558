/* Colors  */
:root {
    --PrimaryColor: hsl(26, 93%, 50%);
    --HoverColor: hsl(26, 90%, 42%);
    --whiteColor: hsl(0, 0%, 100%);
    --blackColor: hsl(0, 0%, 18%);
    --textColor: hsl(240, 1%, 48%);
    --whiteColorDeam: hsl(0, 0%, 93%);
    --greyBg: hsl(0, 0%, 96%);
    --greyText: rgb(190, 190, 190);
    --inputColor: hsl(330, 12%, 97%);
}

.service {
    // .secContainer {
        .title {
            color: var(--PrimaryColor);
            line-height: 1.5rem;
            padding-bottom: .5rem;
            text-align: center;
            font-size: 1.5rem;
            font-weight: 800;

        }

        .videoCard {
            margin-top: 2rem;
            // background: url('../../Assets/background.jpg');
            background: rgb(34,193,195);
            background: linear-gradient(0deg, rgba(34,193,195,1) 0%, rgba(253,139,45,0.8435749299719888) 89%);
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;

            .cardContent {
                gap: 2rem;
                padding: 2rem 1rem;

                .cardText {
                    h2 {
                        color: var(--whiteColor);
                        font-weight: 600;
                        padding-bottom: 1rem;
                        text-shadow: -0.5px -0.5px 0 var(--greyText), 0.5px -0.5px 0 var(--greyText), -0.5px 0.5px 0 var(--greyText), 0.5px 0.5px 0 var(--greyText);

                    }

                    p {
                        color: var(--blackColor);
                        opacity: .7;
                        font-size: 13px;
                        font-weight: 600;
                        letter-spacing: 1px;
                        text-shadow: -0.5px -0.5px 0 var(--greyText), 0.5px -0.5px 0 var(--greyText), -0.5px 0.5px 0 var(--greyText), 0.5px 0.5px 0 var(--greyText);
                    }
                }

                .cardVideo1 {
                    border: 2px solid var(--whiteColor);
                    height: 200px;
                    width: 100%;
                    border-radius: 10px;
                    overflow: hidden;
                    animation: animate 10s ease-in-out infinite;
                    background-position: center;
                    background-size: cover;
                    background-repeat: no-repeat;
                    animation-name: card1;

                    @keyframes card1{

                        0%,
                        100% {
                            background-image: url('../../Assets/office_staff.jpeg');
                        }

                        25% {
                            background-image: url('../../Assets/hospitality.jpeg');
                        }

                        50% {
                            background-image: url('../../Assets/engineer.jpeg');
                        }

                        75% {
                            background-image: url('../../Assets/hospital_stall.jpeg');
                        }
                    }


                }

                .cardVideo2 {
                    border: 2px solid var(--whiteColor);
                    height: 200px;
                    width: 100%;
                    border-radius: 10px;
                    overflow: hidden;
                    animation: animate 10s ease-in-out infinite;
                    background-position: center;
                    background-size: cover;
                    background-repeat: no-repeat;
                    animation-name: card2;

                    @keyframes card2 {

                        0%,
                        100% {
                            background-image: url('../../Assets/construction.jpeg');
                        }

                        14% {
                            background-image: url('../../Assets/unskill_carpenter.jpeg');
                        }

                        28% {
                            background-image: url('../../Assets/driver.jpeg');
                        }

                        42% {
                            background-image: url('../../Assets/security.jpeg');
                        }

                        56% {
                            background-image: url('../../Assets/oil.jpeg');
                        }

                        70% {
                            background-image: url('../../Assets/delivery.jpeg');
                        }

                        84% {
                            background-image: url('../../Assets/shuttering_carpenter.jpeg');
                        }
                    }


                }
            }

        }


    // }

}

// MEDIA QUERIES

@media screen and (min-width:320px){
    .service{
        padding: 8rem 0;
    }
}

@media screen and (min-width:720px) {
    .service {
        // .secContainer {
            .videoCard {
                box-shadow: 0 2px 8px 4px rgba(74, 70, 70, 0.376);
                border-radius: 1rem;
                .cardContent {
                    gap: 4rem;
                    padding: 4rem 3rem;

                    .cardText {
                        h2 {
                            font-size: 2rem;
                        }

                        p {
                            opacity: .7;
                            font-size: 15px;
                        }
                    }
                }

                .cardContent {
                    grid-template-columns: repeat(2, 1fr) !important;
                }
            }
        // }
    }
}

@media screen and (min-width:1024px) {
    .service {
        // .secContainer {
            .mainContent {
                padding: 2.5rem 0;
            }
        // }
    }
}