/* Colors  */
:root {
    --PrimaryColor: hsl(26, 93%, 50%);
    --HoverColor: hsl(26, 90%, 42%);
    --whiteColor: hsl(0, 0%, 100%);
    --blackColor: hsl(0, 0%, 18%);
    --textColor: hsl(240, 1%, 48%);
    --whiteColorDeam: hsl(0, 0%, 93%);
    --greyBg: hsl(0, 0%, 96%);
    --greyText: rgb(190, 190, 190);
    --inputColor: hsl(330, 12%, 97%);
    // box-shadow: 0 2px 8px 2px rgba(178,178,178,0.45);
}

.formContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    position: relative;

    .homeCard {
        width: 100%;
        border-radius: 1rem;
        padding: 11rem 0;
        background: var(--whiteColor);
        // position: absolute;
        gap: 1rem;
        justify-content: center;
        // bottom: -35%;
        // transform: translate(-50%);
        left: 50%;
        // box-shadow: 0 2px 8px 4px rgba(178, 178, 178, 0.45);
        .title{
            text-align: center;
            color: var(--PrimaryColor);
        }

        .inputsDiv {

            display: flex;

            .locationDiv,
            .distDiv,
            .priceDiv {
                width: 100%;
                margin: 10px;

                label {
                    display: block;
                    color: var(--textColor);
                    font-weight: 500;
                    font-size: 13px;
                    padding-bottom: .4rem;
                }

                input {
                    width: 100%;
                    background: var(--inputColor);
                    border-radius: 10px;
                    border: none;
                    padding: 10px;
                    font-size: 13px;
                    font-weight: 500;

                    &::placeholder {
                        font-size: 13px;
                        opacity: .5;
                        font-weight: 400;
                    }

                    &:focus {
                        outline: none;

                    }
                }
            }
        }

        .btn {
            width: 100%;
            padding: .6rem 1.5rem;
            color: var(--whiteColor);
            font-weight: 600;
            background: var(--PrimaryColor);
        }
    }

}

@media screen and (min-width:320px) {
    .formContainer{
        .homeCard{
            .inputsDiv{
                display: flex;
                flex-direction: column;
            }
        }
    }
}

@media screen and (min-width:768px) {
    .formContainer{
        .homeCard{
            .inputsDiv{
                display: flex;
                flex-direction: row;
            }
        }
    }
}